import { DefaultDurations, GqlZoneItemSizeType } from "./store/graphqlTypes";

import { ContentSizeType } from "./types/content";
import { TimelineType } from "./store/timelines/types";

export const DEFAULT_APP_DURATION = 60000;
export const DEFAULT_IMAGE_DURATION = 10000;
export const DEFAULT_LINK_DURATION = 60000;
export const DEFAULT_VIDEO_DURATION = 60000;
export const DEFAULT_AUDIO_DURATION = 60000;
export const DEFAULT_HREF_DURATION = 60000;
export const DEFAULT_DOCUMENT_PAGE_DURATION = 10000;
export const DEFAULT_DURATIONS: DefaultDurations = {
  app: DEFAULT_APP_DURATION,
  image: DEFAULT_IMAGE_DURATION,
  link: DEFAULT_LINK_DURATION,
  document: DEFAULT_DOCUMENT_PAGE_DURATION,
};

export const DEFAULT_APP_PRELOAD_DURATION = 6000;
export const DEFAULT_FILE_PRELOAD_DURATION = 5000;
export const DEFAULT_LINK_PRELOAD_DURATION = 6000;
export const DEFAULT_SITE_PRELOAD_DURATION = 60000;

export const LOGO_YELLOW = "#fdce0b";
export const LOGO_GREY = "#2d313a";

export const POLL_FOR_SCREEN_DATA = 30000;

export const DEFAULT_TIMELINE_SOURCE: TimelineType = "local";

export const DEFAULT_SIZE_TYPE_IMAGE: ContentSizeType = "fit";
export const DEFAULT_SIZE_TYPE_VIDEO: ContentSizeType = "fit";
export const DEFAULT_SIZE_TYPE_DOCUMENT: ContentSizeType = "fit";
export const DEFAULT_SIZE_TYPES: GqlZoneItemSizeType = {
  image: DEFAULT_SIZE_TYPE_IMAGE,
  video: DEFAULT_SIZE_TYPE_VIDEO,
  document: DEFAULT_SIZE_TYPE_DOCUMENT,
};
export const DEFAULT_SIZE_TYPE_FALLBACK: ContentSizeType = "fit";

export const DAY_DURATION_MS = 1000 * 60 * 60 * 24;
export const HOUR_DURATION_MS = 1000 * 60 * 60;
export const MINUTE_DURATION_MS = 1000 * 60;
export const SECOND_DURATION_MS = 1000;

/**
 * ID set by GraphQL for the "Background Audio" zone
 */
export const HIDDEN_ZONE_ID = "hidden";

export const DEACTIVATE_SCREEN_MESSAGE =
  "This screen is currently deactivated. Please log into ScreenCloud to reactivate your screen";
export const PREVIEW_SCREEN_MESSAGE =
  "This screen is currently in trial mode. Please log into ScreenCloud and activate your subscription.";
export const SUSPENDED_SCREEN_MESSAGE =
  "This screen is currently suspended. Please log into ScreenCloud and reactivate your subscription.";

export const FEATURE_FLAGS = {
  playerLogEnable: "player_log_enable",
};

export const DEFAULT_APP_INSTANCE_ID = "00000000-0000-0000-0000-000000000000";

export const TIME_SYNC_INTERVAL = 2 * HOUR_DURATION_MS;
