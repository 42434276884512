import { LocalStorage } from "./storage";
import { HOUR_DURATION_MS } from "./constants";
import { Logger } from "./logger/logger";
import { CustomStorageEstimate, ExpirationData } from "./types";
export const scServiceWorkerHistory = "__sc_service_worker_history";

const log = new Logger("serviceWorkerManager");

const hoursInMs = (numberOfHours: number): number =>
  numberOfHours * HOUR_DURATION_MS;
const ServiceWorkerUpdateCheckFrequency = hoursInMs(1);

export const MAX_HISTORY_ITEMS = 50;
interface HistoryEvent {
  type: string;
  data?: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  timestamp?: Date;
}

const isSupported = "serviceWorker" in window.navigator;

export class ServiceWorkerManager {
  private static _instance: ServiceWorkerManager;
  reloading = false;
  updateIntervalRef: number;
  history: HistoryEvent[] = [];
  registration: ServiceWorkerRegistration | null = null;

  constructor() {
    this.handleControllerChange = this.handleControllerChange.bind(this);
    this.handleUpdateFound = this.handleUpdateFound.bind(this);
    this.update = this.update.bind(this);

    if (isSupported) {
      navigator.serviceWorker.addEventListener(
        "controllerchange",
        this.handleControllerChange
      );
    }

    this.initHistory();

    this.updateIntervalRef = window.setInterval(
      this.update,
      ServiceWorkerUpdateCheckFrequency
    );
  }

  // TODO: this is not gonna be accurate if several tabs are open. Move logging to active service worker?
  // We only need this to understand/debug how service workers work. Can remove after we are satisfied with the workflow
  initHistory(): void {
    try {
      const history = LocalStorage.getInstance().getItem(
        scServiceWorkerHistory
      );
      if (Array.isArray(history)) {
        this.history = history;
      } else {
        LocalStorage.getInstance().removeItem(scServiceWorkerHistory);
      }
    } catch (err) {
      log.error({
        message: "History init error:",
        context: { error: JSON.stringify(err) },
      });
    }
  }
  getHistory(): HistoryEvent[] {
    return this.history;
  }

  addEventToHistory(event: HistoryEvent): void {
    try {
      this.history.push({ ...event, timestamp: new Date() });
      this.history = this.history.slice(
        Math.max(this.history.length - MAX_HISTORY_ITEMS, 0)
      );
      LocalStorage.getInstance().setItem(scServiceWorkerHistory, this.history);
    } catch (error) {
      log.warn({
        message: `Unable to add history event: ${{ ...event }}`,
        context: {
          error: JSON.stringify(error),
        },
        proofOfPlayFlag: true,
      });
    }
  }

  static getInstance(): ServiceWorkerManager {
    if (!this._instance) {
      this._instance = new ServiceWorkerManager();
    }

    return this._instance;
  }

  reload(): void {
    if (this.reloading) return; // make sure Update on Reload does not cause a loop
    this.reloading = true;
    log.info("Reloading page!");
    window.location.reload();
  }

  handleControllerChange(): void {
    this.addEventToHistory({ type: "controllerchange" });
    // This fires when the service worker controlling this page
    // changes, eg a new worker has skipped waiting and become
    // the new active worker.
    log.debug("Service worker controller changed");
    this.reload();
  }

  handleUpdateFound(): void {
    this.addEventToHistory({ type: "updatefound" });
    // A new service worker has appeared in reg.installing!
    // registration.installing: the installing worker, or undefined
    // registration.waiting: the waiting worker, or undefined
    // registration.active: the active worker, or undefined
    const newWorker = this.registration?.installing; // There is a registration and service worker at this state for sure
    if (!newWorker) return;

    newWorker.addEventListener("statechange", () =>
      this.handleServiceWorkerStateChange(newWorker)
    );
  }

  handleServiceWorkerStateChange(serviceWorker: ServiceWorker): void {
    this.addEventToHistory({ type: "statechange", data: serviceWorker.state });
    // 'installing' - the install event has fired, but not yet complete
    // 'installed'  - install complete
    // 'activating' - the activate event has fired, but not yet complete
    // 'activated'  - fully active
    // 'redundant'  - discarded. Either failed install, or it's been
    //                replaced by a newer version
    log.debug({
      message: "Service worker state changed",
      context: {
        state: JSON.stringify(serviceWorker.state),
      },
    });

    if (serviceWorker.state === "installed") {
      // The new service worker is installed and ready to be activated.
      this.handleServiceWorkerInstallation(serviceWorker);
    }
  }

  // This function decides what to do with the new service worker that is installed already
  handleServiceWorkerInstallation(serviceWorker: ServiceWorker): void {
    // Just an example about how to show user/developer that the service worker
    // is gonna refresh the page to activate the latest service worker
    if (process.env.REACT_APP_SC_ENV === "development") {
      const div = document.createElement("div");
      div.style.position = "fixed";
      div.style.bottom = "32px";
      div.style.right = "10px";
      div.style.zIndex = "10000";
      div.style.background = "white";
      div.style.border = "1px solid red";
      div.style.color = "black";
      div.innerHTML = "REFRESHING IN 5 SECONDS";
      div.id = "service-worker-installation";
      document.body.appendChild(div);
    }

    setTimeout(() => {
      // back up reload after 1min if controllerchange event doesn't come back
      this.addEventToHistory({
        type: "force-reload",
        data: serviceWorker.state,
      });

      this.reload();
    }, 60 * 1000);

    setTimeout(() => {
      this.activateServiceWorker(serviceWorker);
    }, 5000);
  }

  activateServiceWorker(serviceWorker: ServiceWorker): void {
    // Service worker is gonna go to activated mode after this and when the tab is reloaded via handleControllerChange
    // this client is gonna be controlled by this new service worker.
    try {
      serviceWorker.postMessage({ type: "skipWaiting" });
    } catch (error) {
      log.warn({
        message: "activate failed",
        context: { error: JSON.stringify(error) },
        proofOfPlayFlag: true,
      });
    }
  }

  clearServiceWorkerCache(): Promise<boolean | string> {
    return new Promise((resolve, reject) => {
      if (navigator?.serviceWorker?.controller) {
        const serviceWorker = navigator.serviceWorker.controller;
        try {
          const clearCacheChannel = new MessageChannel();
          clearCacheChannel.port1.onmessage = (event) => {
            resolve(event.data);
          };
          serviceWorker.postMessage({ type: "clearCache" }, [
            clearCacheChannel.port2,
          ]);
        } catch (error) {
          log.warn({
            message: "clear cache failed",
            context: { error: JSON.stringify(error) },
            proofOfPlayFlag: true,
          });
        }
      } else {
        reject("serviceWorker not available");
      }
    });
  }

  async getServiceWorkerStorage(): Promise<CustomStorageEstimate> {
    return new Promise((resolve, reject) => {
      if (navigator?.serviceWorker?.controller) {
        const serviceWorker = navigator.serviceWorker.controller;
        try {
          // https://developer.mozilla.org/docs/Web/API/MessageChannel
          const storageChannel = new MessageChannel();
          storageChannel.port1.onmessage = (event) => {
            resolve(event.data);
          };

          serviceWorker.postMessage({ type: "getStorage" }, [
            storageChannel.port2,
          ]);
        } catch (error) {
          log.warn({
            message: "get storage failed",
            context: { error: JSON.stringify(error) },
            proofOfPlayFlag: true,
          });
          reject(error);
        }
      } else {
        reject("serviceWorker not available");
      }
    });
  }

  async getServiceWorkerAllCachedDataByName(
    name: string
  ): Promise<{ allData: ExpirationData[] }> {
    return new Promise((resolve, reject) => {
      if (navigator?.serviceWorker?.controller) {
        const serviceWorker = navigator.serviceWorker.controller;
        try {
          // https://developer.mozilla.org/docs/Web/API/MessageChannel
          const dataModelChannel = new MessageChannel();
          dataModelChannel.port1.onmessage = (event) => {
            resolve(event.data);
          };

          serviceWorker.postMessage(
            { type: "getDataModelCache", dataModelName: name },
            [dataModelChannel.port2]
          );
        } catch (error) {
          log.warn({
            message: "get data model failed",
            context: { error: JSON.stringify(error) },
            proofOfPlayFlag: true,
          });
          reject(error);
        }
      } else {
        reject("serviceWorker not available");
      }
    });
  }

  async setServiceWorkerMediaCacheURL(url: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (navigator?.serviceWorker?.controller) {
        const serviceWorker = navigator.serviceWorker.controller;
        try {
          // https://developer.mozilla.org/docs/Web/API/MessageChannel
          const mediaCacheURLChannel = new MessageChannel();
          mediaCacheURLChannel.port1.onmessage = (event) => {
            resolve(event.data);
          };

          serviceWorker.postMessage(
            { type: "setMediaCacheURL", mediaCacheURL: url },
            [mediaCacheURLChannel.port2]
          );
        } catch (error) {
          log.warn({
            message: "set mediacache url failed",
            context: { error: JSON.stringify(error) },
            proofOfPlayFlag: true,
          });
          reject(error);
        }
      } else {
        reject("serviceWorker not available");
      }
    });
  }

  // ignoreSupportCheck is an argument for the testing purpose
  async register(ignoreSupportCheck?: boolean): Promise<void> {
    if (ignoreSupportCheck || isSupported) {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(
        (process as { env: { [key: string]: string } }).env.PUBLIC_URL,
        window.location.href
      );

      if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebook/create-react-app/issues/2374
        return;
      }

      const swUrl = `${process.env.PUBLIC_URL}/serviceworker.js`;
      try {
        this.registration = await navigator.serviceWorker
          .register(swUrl, { scope: "/" })
          .catch((err) => {
            log.error({
              message: "ServiceWorker registration failed: ",
              context: {
                errorMessage: err.message,
              },
            });
            return null;
          });

        if (!this.registration) return;

        log.debug({
          message: "ServiceWorker registration successful with scope: ",
          context: {
            registrationscope: this.registration.scope,
          },
        });
        this.registration.addEventListener(
          "updatefound",
          this.handleUpdateFound
        );
      } catch (error) {
        log.error({
          message: "ServiceWorker unable to register:",
          context: {
            error: JSON.stringify(error),
          },
        });
      }
    } else {
      return;
    }
  }
  // ignoreSupportCheck is an argument for the testing purpose
  async unregister(ignoreSupportCheck?: boolean): Promise<void> {
    if (ignoreSupportCheck || isSupported) {
      const registration = await navigator.serviceWorker.ready; // ready promise never rejects, waits until service worker is active
      if (registration) {
        return registration
          .unregister()
          .then((done) => {
            if (done) {
              this.registration = null;
              log.info("ServiceWorker unregistered successfully");
              return;
            }
            throw new Error("false"); // Unregistration may fail even if the promise is successful
          })
          .catch((err) => {
            log.error({
              message: "ServiceWorker unregisteration failed:",
              context: { error: err },
            });
          });
      } else {
        log.warn({
          message:
            "ServiceWorkerRegistration associated with the current page NOT has an active worker.",
          proofOfPlayFlag: true,
        });
        return;
      }
    } else {
      return;
    }
  }

  update(): void {
    log.info({ message: "Checking service worker update" });
    try {
      this.registration?.update();
    } catch (error) {
      // handled error so sentry not capture this
      log.error({
        message: "registration update error",
        context: { error: JSON.stringify(error) },
      });
    }
  }

  stop(): void {
    clearInterval(this.updateIntervalRef);
  }
}

// Our staged rollout is relied on js-player appended params in order to route to the correct bucket on AWS, so we need to only focus to those specific params
// ref: https://github.com/screencloud/screencloud-js-player/blob/fd0b8fa4da3915548ebf0d793cdb24b2a82912b4/lib/player/player.js#L512
export const getOnlyStagedRolloutParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const paramsToInclude = ["component_name", "screen_id"]; // This is needed to be sync with js-player and studio-player's lambda

  const includedParams = paramsToInclude.filter((param) =>
    searchParams.has(param)
  );
  if (includedParams.length === 0) {
    return "";
  }

  const result = includedParams
    .map((param) => `${param}=${searchParams.get(param)}`)
    .join("&");
  return `?${result}`;
};
