export enum FEATURE_FLAGS_ENUM {
  PLAYBACK_LOGS = "playback_logs",
  PLAYER_LOG_ENABLE = "player_log_enable",
  PERSISTENT_VIDEO = "persistent_video",
  FORCE_USE_SERVICEWORKER_IMAGES = "force_use_serviceworker_images", // switch to foce using service worker instead and enabled to use media cache by default
  SECURE_MEDIA_URLS = "secure_media_urls",
  DISABLE_PRELOAD_VIDEO = "disable_preload_video",
}

export type CurrentFeatureFlag = string[];
